import { Injectable, signal } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

interface BreadCrumb {
  main?: string;
  next?: string;
  primary?: string;
  secondary?: string;
  mainRoute?: string;
  nextRoute?: string;
  primaryRoute?: string;
}

@Injectable({
  providedIn: 'root',
})
export class BreadCrumbService {
  currentPage = signal<BreadCrumb | null>(null);

  constructor(private router: Router) {}

  setCurrentPage(breadCrumb: BreadCrumb) {
    this.currentPage.set(breadCrumb);
  }

  setPageBreadCrumb(activatedRoute: ActivatedRoute) {
    this.setCurrentPage({
      main: activatedRoute.snapshot.data['breadcrumbMain'],
      next: activatedRoute.snapshot.data['breadcrumbNext'],
      primary: activatedRoute.snapshot.data['primaryBreadCrumb'],
      secondary: activatedRoute.snapshot.data['secondaryBreadCrumb'],
      mainRoute: activatedRoute.snapshot.data['mainRoute'],
      nextRoute: activatedRoute.snapshot.data['nextRoute'],
      primaryRoute: activatedRoute.snapshot.data['primaryRoute'],
    });
  }

  navigateToMain() {
    const mainRoute = this.currentPage()?.mainRoute;
    if (mainRoute) {
      this.router.navigate([mainRoute]);
    }
  }

  navigateToNext() {
    const nextRoute = this.currentPage()?.nextRoute;
    if (nextRoute) {
      this.router.navigate([nextRoute]);
    }
  }

  navigateToPrimary() {
    const primaryRoute = this.currentPage()?.primaryRoute;
    if (primaryRoute) {
      this.router.navigate([primaryRoute]);
    }
  }
}